import 'rxjs/add/operator/pairwise';
import 'rxjs/add/operator/filter';
import {Injectable, Inject} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, NavigationCancel} from '@angular/router';
import {isDevMode} from '@angular/core';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {SESSION_STORAGE, WebStorageService} from 'ngx-webstorage-service';
import { NavigationService } from '../navigation.service';

@Injectable()
export class AuthGuard implements CanActivate {
  private isAuthenticated = false; // Set this value dynamically
  private userData = null;
  public returnUrl : string = null;
  
  public BuyerRoleName : string = 'Buyer';
  public ManagerRoleName : string = 'Manager';
  public DirectorRoleName : string = 'Director';
  public ChairmanRoleName : string = 'Chairman';
  public AdminRoleName : string = 'Admin';
  public VPRoleName : string = 'VicePresident';
  public SuperUserRoleName: string = "SuperUser";

  public ForecastPlanner: string = "Planner";
  public ForecastEvaluator: string = "Evaluator";
  public ForecastApprover: string = "Approver";
  
  @BlockUI() blockUI: NgBlockUI;

  constructor(
    private router: Router,
    @Inject(SESSION_STORAGE) private storage: WebStorageService,
    private navigation: NavigationService
  ) {
      this.userData = storage.get("SARUserData");
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.redirectIfNeed(state);

    if (this.isAuthenticated) {
      return true;
    }
    if(isDevMode() && this.userData != null){
      console.log(this.userData);
    }
    if(typeof(this.userData) != 'undefined' && this.userData != null){
      var d = new Date(this.userData.expiration);
      if(d.getTime() > new Date().getTime()){
        this.isAuthenticated = true; 
        this.filterMenuByUserRoles();
        return true;
      }
    }
    this.returnUrl = state.url;
    this.logout();
    return false;
  }

  private redirectIfNeed(state: RouterStateSnapshot){
    this.navigation.menuItems$.subscribe(items => {
      if (!state.url.includes("sar/validate-sar/") && !state.url.includes("forecast")  && state.url != `/${items[0].state}`){
        this.router.navigate([`/${items[0].state}`]);
      }
    });
    // if (state.url != "/sar/sar-dashboard" && !state.url.includes("sar/validate-sar/")){
    //   this.router.navigate(["/sar/sar-dashboard"]);
    // }
  }

  private filterMenuByUserRoles(){
    this.navigation.menuItems$.subscribe(items => {
      var menu = [];
      for(let i = 0; i < items.length; i++){
        let canAdd = false;
        if(items[i].roles != null){
          for(let r = 0; r < items[i].roles.length; r++){
            if(this.isInRole(items[i].roles[r])){
              canAdd = true;
              if (items[i].sub && items[i].sub.length > 0){
                items[i].sub = items[i].sub.filter(item => item.roles.some(r => this.isInRole(r)));
              }
              break;
            }
          }
        } else {
          canAdd = true;
        }
        if(canAdd) menu.push(items[i]);
      }

      if (menu.length == 1 && menu[0].sub && menu[0].sub.length > 0){
        menu = menu[0].sub;
      }

      this.navigation.updateMenu(menu);
    });
  }

  logout(){
    this.userData = null;
    this.isAuthenticated = false;
    this.navigation.resetMenu();
    this.storage.remove("SARUserData");
    this.storage.remove("SARAuthKey");
    let args = {};
    if(this.returnUrl != null && this.returnUrl != '' && this.returnUrl != '/dashboard'){
      args = { queryParams: { returnUrl: this.returnUrl } };
    }
    this.router.navigate(['/sessions/signin'], args);
  }

  doLogin(data : any){
    this.userData = data;
    this.storage.set("SARUserData", data);
    if(isDevMode()){
      console.log(this.userData);
    }
    this.isAuthenticated = true;

    this.filterMenuByUserRoles();
    //this.blockUI.stop();
  } 

  isManager(){ return this.isInRole(this.ManagerRoleName); }
  isBuyer(){ return this.isInRole(this.BuyerRoleName); }
  isDirector(){ return this.isInRole(this.DirectorRoleName); }
  isChairman(){ return this.isInRole(this.ChairmanRoleName); }
  isAdmin(){ return this.isInRole(this.AdminRoleName)}
  isSuperUser() { return this.isInRole(this.SuperUserRoleName); }

  isForecastPlanner(){ return this.isInRole(this.ForecastPlanner); }
  isForecastEvaluator() { return this.isInRole(this.ForecastEvaluator); }
  isForecastApprover() { return this.isInRole(this.ForecastApprover); }

  isInRole(role : string){
    if(this.userData == null) return false;
    if(this.userData.roles.length == 0) return false;
    for(var i = 0; i < this.userData.roles.length; i++){
      if(this.userData.roles[i].toLowerCase() == role.toLowerCase() || this.userData.roles[i].toLowerCase() == this.SuperUserRoleName.toLowerCase()){
        return true;
      }
    }
    return false;
  }

  getProperty(key : string){
    if(this.userData == null) return '';
    return this.userData[key];
  }
  
  setIsAuthenticated(isAuthenticaded: boolean) {
    this.isAuthenticated = isAuthenticaded;
  }
  getColor(userId : string){
    return "#eee";
  }

  isCurrentUser(userId: string): boolean{
    return this.userData.id == userId;
  }

  hasBulkUploadEnabled(){
    return this.userData.company?.departments?.some(department => department.enableBulkUpload);
  }

}
