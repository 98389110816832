import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Observable, of} from 'rxjs';
import {catchError, exhaustMap, map, tap} from 'rxjs/operators';
import {AuthService} from '../auth.service';
import {Action, Store} from '@ngrx/store';
import {ILoginModel} from '../models/i-login.model';
// import { JwtHelperService } from '@auth0/angular-jwt';

import * as userActions from './user.actions';
import {Router} from '@angular/router';
import {IAuthModel} from '../models/i-auth.model';
import {AuthGuard} from '../../../shared/services/auth/auth.guard';
import {ICreateUserModel} from '../models/create-user/i-create-user.model';
import {ISearchUserModel} from '../../sar/models/meeting/i-search-user.model';
import {UserService} from '../user.service';
import {ICoreSearchModel} from '../../core/models/i-core-search.model';
import {IUserDashboardModel} from '../models/user-list/i-user-dashboard.model';
import {IRoleModel} from '../models/create-user/i-role.model';
import {IRoleAttributeModel} from '../models/create-user/i-role-attribute.model';
import {IUserModel} from '../models/i-user.model';
import {IUpdateUserModel} from '../models/create-user/i-update-user.model';

import * as fromRefdata from '../../refdata/state';
import * as refdataActions from '../../refdata/state/refdata.actions';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from 'src/app/shared/services/navigation.service';

@Injectable()
export class UserEffects {

  @BlockUI() blockUI: NgBlockUI;

  @Effect()
  create$: Observable<Action> = this.actions$.pipe(
    ofType(userActions.UserActionTypes.Create),
    map((action: userActions.Create) => action.payload),
    exhaustMap((m: ICreateUserModel) =>
      this.authService.create(m).pipe(
        map((loggedInUser: any) => (new userActions.CreateSuccess('success'))),
        catchError(err => of(new userActions.CreateFail(err)))
      )
    )
  );

  @Effect()
  update$: Observable<Action> = this.actions$.pipe(
    ofType(userActions.UserActionTypes.Update),
    map((action: userActions.Update) => action.payload),
    exhaustMap((m: IUpdateUserModel) =>
      this.authService.update(m).pipe(
        map((loggedInUser: any) => (new userActions.UpdateSuccess('success'), new userActions.GetDashboardUsers(<ICoreSearchModel>{currentPage: 1, pageSize: 100}))),
        catchError(err => of(new userActions.UpdateFail(err)))
      )
    )
  );

  @Effect()
  login$: Observable<Action> = this.actions$.pipe(
    ofType(userActions.UserActionTypes.Login), 
    map((action: userActions.Login) => action.payload),
    exhaustMap((loginModel: ILoginModel) =>
      this.authService.login(loginModel).pipe(
        tap((auth: IAuthModel) => (this.authService.setToken(auth.token), this.authGuard.doLogin(auth))),
        map((loggedInUser: any) => (new userActions.LoginSuccess(loggedInUser))),
        catchError(err => of(new userActions.ShowGlobalFeedback(err),new userActions.LoginFail(err))),
      )
    )
  );

  @Effect({dispatch: false})
  logout$: Observable<Action> = this.actions$.pipe(
    ofType(userActions.UserActionTypes.Logout), 
    tap(() => {
      this.router.navigate(['/sessions/signin']);
    })
  );

  @Effect({dispatch: false})
  loginSuccess$: Observable<Action> = this.actions$.pipe(
    ofType(userActions.UserActionTypes.LoginSuccess), 
    tap(() => {
      localStorage.removeItem('SAR-Dashboard-DataRequest');
      
      this.storeRefdata.dispatch(new refdataActions.GetRefdata(refdataActions.RefdataType.location));
      this.storeRefdata.dispatch(new refdataActions.GetRefdata(refdataActions.RefdataType.usedEquipment));
      this.storeRefdata.dispatch(new refdataActions.GetRefdata(refdataActions.RefdataType.indirectPurchasingCategory));
      this.storeRefdata.dispatch(new refdataActions.GetRefdata(refdataActions.RefdataType.fhaRate));
      this.storeRefdata.dispatch(new refdataActions.GetRefdata(refdataActions.RefdataType.paymentTerms));
      this.storeRefdata.dispatch(new refdataActions.GetRefdata(refdataActions.RefdataType.certification));
      this.storeRefdata.dispatch(new refdataActions.GetRefdata(refdataActions.RefdataType.categoryType));
      this.storeRefdata.dispatch(new refdataActions.GetRefdata(refdataActions.RefdataType.category));
      this.storeRefdata.dispatch(new refdataActions.GetRefdata(refdataActions.RefdataType.subCategory));
      this.storeRefdata.dispatch(new refdataActions.GetRefdata(refdataActions.RefdataType.itemCategory));
      this.storeRefdata.dispatch(new refdataActions.GetRefdata(refdataActions.RefdataType.unitMeasure));
      this.storeRefdata.dispatch(new refdataActions.GetRefdata(refdataActions.RefdataType.plant));
      this.storeRefdata.dispatch(new refdataActions.GetRefdata(refdataActions.RefdataType.storageLocation));
      this.storeRefdata.dispatch(new refdataActions.GetRefdata(refdataActions.RefdataType.region));
      this.storeRefdata.dispatch(new refdataActions.GetRefdata(refdataActions.RefdataType.costCenter));
      this.storeRefdata.dispatch(new refdataActions.GetRefdata(refdataActions.RefdataType.assetNumber));
      this.storeRefdata.dispatch(new refdataActions.GetRefdata(refdataActions.RefdataType.assetSubNumber));
      this.storeRefdata.dispatch(new refdataActions.GetRefdata(refdataActions.RefdataType.orderNumber));
      this.storeRefdata.dispatch(new refdataActions.GetRefdata(refdataActions.RefdataType.ledgerAccount));
      this.storeRefdata.dispatch(new refdataActions.GetRefdata(refdataActions.RefdataType.orgs));
      this.storeRefdata.dispatch(new refdataActions.GetCountries());
      this.storeRefdata.dispatch(new refdataActions.GetExchangeRates());

      if(this.authGuard.returnUrl != null && this.authGuard.returnUrl != '' && this.authGuard.returnUrl != '/dashboard'){
        if (this.authGuard.returnUrl.includes("?")) {
          this.router.navigateByUrl(this.authGuard.returnUrl);
          return;
        }
        this.router.navigate([this.authGuard.returnUrl]);
        return;
      }

      this.navigation.menuItems$.subscribe(items => {
        this.router.navigate([`/${items[0].state}`]);
      });

      // if(this.authGuard.isChairman() && !(this.authGuard.isBuyer() || this.authGuard.isDirector())){
      //   this.router.navigate(['/agenda/sourcing-session-a11genda']);
      //   return;
      // }
      // this.router.navigate(['/sar/sar-dashboard']);
    })
  );

  @Effect({dispatch: false})
  loginFail$: Observable<Action> = this.actions$.pipe(
    ofType(userActions.UserActionTypes.LoginFail),
    // tap(() => window.location.reload())
    tap(() =>  {
      this.blockUI.stop();
      this.translateService.get("Sorry, we couldn't find an account with that username or the entered password is invalid").subscribe(res => {
        this.snackBar.open(res, undefined, {
          verticalPosition: "bottom", 
          horizontalPosition: "right",
          duration: 4000,
        });
      });
    })
  );

  @Effect()
  search$: Observable<Action> = this.actions$.pipe(
    ofType(userActions.UserActionTypes.Search),
    map((action: userActions.Search) => action.payload),
    exhaustMap((search: string) =>
      this.userService.search(search).pipe(
        map((result: ISearchUserModel[]) => (new userActions.SearchSuccess(result))),
        catchError(err => of(new userActions.SearchFail(err)))
      )
    )
  );

  @Effect()
  getUsers$: Observable<Action> = this.actions$.pipe(
    ofType(userActions.UserActionTypes.GetUsers),
    exhaustMap(() =>
      this.userService.getUsers().pipe(
        map((result: IUserModel[]) => (new userActions.GetUsersSuccess(result))),
        catchError(err => of(new userActions.GetUsersError(err)))
      )
    )
  );

  @Effect()
  getDashboardUsers$: Observable<Action> = this.actions$.pipe(
    ofType(userActions.UserActionTypes.GetDashboardUsers),
    map((action: userActions.GetDashboardUsers) => action.payload),
    exhaustMap((search: ICoreSearchModel) =>
      this.userService.getDashboard(search).pipe(
        map((result: IUserDashboardModel[]) => (new userActions.GetDashboardUsersSuccess(result))),
        catchError(err => of(new userActions.GetDashboardUsersError(err)))
      )
    )
  );

  @Effect()
  getRoles$: Observable<Action> = this.actions$.pipe(
    ofType(userActions.UserActionTypes.GetRoles),
    exhaustMap(() =>
      this.userService.getRoles().pipe(
        map((result: IRoleModel[]) => (new userActions.GetRolesSuccess(result))),
        catchError(err => of(new userActions.GetRolesError(err)))
      )
    )
  );

  @Effect()
  getRoleAttributes$: Observable<Action> = this.actions$.pipe(
    ofType(userActions.UserActionTypes.GetRoleAttributes),
    exhaustMap(() =>
      this.userService.getRoleAttributes().pipe(
        map((result: IRoleAttributeModel[]) => (new userActions.GetRoleAttributesSuccess(result))),
        catchError(err => of(new userActions.GetRoleAttributesError(err)))
      )
    )
  );

  @Effect()
  getUser$: Observable<Action> = this.actions$.pipe(
    ofType(userActions.UserActionTypes.GetUser),
    map((action: userActions.GetUser) => action.payload),
    exhaustMap((userId: string) =>
      this.userService.getById(userId).pipe(
        map((result: IUserModel) => (new userActions.GetUserSuccess(result))),
        catchError(err => of(new userActions.GetUserError(err)))
      )
    )
  );

  constructor(private router: Router,
              private authGuard: AuthGuard,
              private authService: AuthService,
              private userService: UserService,
              private actions$: Actions,
              private storeRefdata: Store<fromRefdata.State>,
              private snackBar: MatSnackBar,
              private translateService: TranslateService,
              private navigation: NavigationService
  ) {}
}
