import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import { IAppModuleModel } from 'src/app/views/refdata/models/i-app-settings.model';
import { AppService } from './app.service';

interface IMenuItem {
  type: string;       // Possible values: link/dropDown/icon/separator/extLink
  name?: string;      // Used as display text for item and title for separator type
  state?: string;     // Router state
  icon?: string;      // Material icon name
  tooltip?: string;   // Tooltip text
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  badges?: IBadge[];
  roles: string[];   // @dherbe added to be able to filter item based on user roles
  module: string;
}

interface IChildItem {
  type?: string;
  name: string;       // Display text
  state?: string;     // Router state
  fullState?: string;
  icon?: string;
  roles?: string[];
  sub?: IChildItem[];
}

interface IBadge {
  color: string;      // primary/accent/warn/hex color codes(#fff000)
  value: string;      // Display text
}

@Injectable()
export class NavigationService {

  defaultMenu: IMenuItem[] = [
    {
      name: 'Purchasing',
      type: 'dropDown',
      tooltip: 'Purchase',
      icon: 'account_balance_wallet',
      state: 'sar/sar-dashboard',
      roles : ['Buyer','Manager','Director','Admin', 'Requestor', 'Chairman', 'VicePresident'],
      sub: [
        {
          type: 'link',
          name: "Purchase Requests",
          state: 'sar/sar-dashboard',
          fullState: 'sar/sar-dashboard',
          icon: 'library_books',
          roles: ['Buyer','Manager','Director','Admin', 'Requestor', 'Chairman', 'VicePresident']
        },
        {
          type: 'link',
          name: 'My Activity',
          state: 'dashboard',
          fullState: 'dashboard',
          icon: 'bubble_chart',
          roles : ['Buyer','Manager','Director','Admin']
        },
        {
          name: 'Session Agenda',
          type: 'link',
          icon: 'calendar_today',
          state: 'agenda/sourcing-session-agenda',
          roles : ['SuperUser']
        },
      ],
      module: "purchase-requests"
    },
    {
      name: 'Purchase Orders',
      type: 'dropDown',
      tooltip: 'Purchase Orders',
      icon: 'library_books',
      state: 'purchase-orders',
      roles : [],
      sub: [
        {
          type: 'link',
          name: "Aggregate PR's",
          state: 'group',
        },
        {
          type: 'link',
          name: 'History',
          state: 'history',
        }
      ],
      module: "purchase-requests"
    },
    {
      name: 'Supply Chain',
      type: 'dropDown',
      tooltip: 'Supply Chain',
      icon: 'precision_manufacturing',
      state: '',
      roles : ['SuperUser', 'Admin', 'Planner', 'Evaluator', 'Approver'],
      module: "forecast",
      sub: [
        {
          type: 'link',
          name: "Forecast",
          state: 'forecast',
          fullState: 'forecast',
          icon: 'insights',
          roles : ['SuperUser', 'Admin', 'Planner', 'Evaluator', 'Approver']
        }
        // ,
        // {
        //   type: 'link',
        //   name: 'Settings',
        //   state: 'forecast/settings',
        //   fullState: 'forecast/settings',
        //   icon: 'bar_chart',
        //   roles : ['SuperUser', 'Admin', 'Evaluator']
        // }
      ]
    },
    {
      name: 'Admin',
      type: 'dropDown',
      tooltip: 'Admin',
      icon: 'settings_suggest',
      state: 'user/user-dashboard',
      roles : ['Admin', 'SuperUser'],
      sub: [
        {
          type: 'link',
          name: "Users",
          state: 'user/user-dashboard',
          fullState: 'user/user-dashboard',
          icon: 'group',
          roles : ['Admin']
        },
        {
          type: 'link',
          name: 'Event viewer',
          state: 'admin/logging',
          fullState: 'admin/logging',
          icon: 'clear_all',
          roles : ['SuperUser']
        },
        {
          type: 'link',
          name: 'Settings',
          state: 'settings',
          fullState: 'settings',
          icon: 'settings',
          roles : ['SuperUser']
        },
        {
          type: 'link',
          name: 'Departments',
          state: 'administration/department-settings',
          fullState: 'administration/department-settings',
          icon: 'account_tree',
          roles : ['Admin']
        }
      ],
      module: "admin"
    }
  ];

  // Icon menu TITLE at the very top of navigation.
  // This title will appear if any icon type item is present in menu.
  iconTypeMenuTitle = 'Frequently Accessed';
  // sets iconMenu as default;
  menuItems;
  // navigation component has subscribed to this Observable
  menuItems$;

  constructor(private appService: AppService) {
    this.filterMenuByAvailableModules();
    this.updateMenu(this.defaultMenu);
  }

  private filterMenuByAvailableModules(){
    const availableModules: IAppModuleModel[] = this.appService.getAvailableModules();
    if (availableModules && availableModules.length){
      this.defaultMenu = this.defaultMenu.filter(menu => 
        availableModules.some(module => 
          module.module == menu.module && (!module.settings?.disabledMenus?.some(state => state == menu.state))
        )
      );
    }
  }

  updateMenu(menuItems: IMenuItem[]){
    this.menuItems = new BehaviorSubject<IMenuItem[]>(menuItems);
    this.menuItems$ = this.menuItems.asObservable();
  }

  resetMenu(){
    this.updateMenu(this.defaultMenu);
  }
  

  // Customizer component uses this method to change menu.
  // You can remove this method and customizer component.
  // Or you can customize this method to supply different menu for
  // different user type.
  publishNavigationChange(menuType: string) {
    this.menuItems.next(this.defaultMenu);
  }
}
