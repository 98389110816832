import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, exhaustMap, map, mergeMap, tap, take, switchMap} from 'rxjs/operators';

/* NgRx */
import {Action} from '@ngrx/store';
import {Actions, Effect, ofType} from '@ngrx/effects';

import * as refdataActions from './refdata.actions';
import {RefdataService} from '../refdata.service';
import {IRefdataGetRequestModel} from '../models/store/i-refdata.get.request.model';
import {IRefdataGetResponseModel} from '../models/store/i-refdata.get.response.model';
import {ICountryRefdataModel} from '../models/i-country-refdata.model';
import {IExchangeRateRefdataModel} from '../models/i-exchange-rate-refdata.model';
import { IMaterialRefdataModel } from '../models/i-material-refdata.model';
import { IMaterialGroupRefdataModel } from '../models/i-material-group-refdata.model';
import { IChannelRefdataModel } from '../models/i-channel-refdata.model';

@Injectable()
export class RefdataEffects {

  @Effect()
  getRefdataDashboard$: Observable<Action> = this.actions$.pipe(
    ofType(refdataActions.RefdataActionTypes.GetRefdata),
    map((action: refdataActions.GetRefdata) => action.payload),
    mergeMap((request: number) =>
      this.srvRefdata.getByRefdataTypeId<IRefdataGetResponseModel>(request).pipe(
        map((response: IRefdataGetResponseModel) => (new refdataActions.GetRefdataSuccess(response))),
        catchError(err => of(new refdataActions.GetRefdataError(err),
          new refdataActions.ShowGlobalFeedback(err)))
      )
    )
  );

  @Effect()
  getCountriesRefdata$: Observable<Action> = this.actions$.pipe(
    ofType(refdataActions.RefdataActionTypes.GetCountries),
    mergeMap(() =>
    this.srvRefdata.getCountriesRefdata<ICountryRefdataModel[]>().pipe(
        map((response: ICountryRefdataModel[]) => (new refdataActions.GetCountriesSuccess(response))),
        catchError(err => of(new refdataActions.GetCountriesError(err),
          new refdataActions.ShowGlobalFeedback(err)))
      )
    )
  );

  @Effect()
  getExchangeRatesRefdata$: Observable<Action> = this.actions$.pipe(
    ofType(refdataActions.RefdataActionTypes.GetExchangeRates),
    mergeMap(() =>
    this.srvRefdata.getExchangeRatesRefdata<IExchangeRateRefdataModel[]>().pipe(
        map((response: IExchangeRateRefdataModel[]) => (new refdataActions.GetExchangeRatesSuccess(response))),
        catchError(err => of(new refdataActions.GetExchangeRatesError(err),
          new refdataActions.ShowGlobalFeedback(err)))
      )
    )
  );

  // @Effect()
  // getMaterialsRefdata$: Observable<Action> = this.actions$.pipe(
  //   ofType(refdataActions.RefdataActionTypes.GetMaterials),
  //   mergeMap(() =>
  //   this.srvRefdata.getMaterials<IMaterialRefdataModel[]>().pipe(
  //       map((response: IMaterialRefdataModel[]) => (new refdataActions.GetMaterialsSuccess(response))),
  //       catchError(err => of(new refdataActions.GetMaterialsError(err),
  //         new refdataActions.ShowGlobalFeedback(err)))
  //     )
  //   )
  // );

  @Effect()
  getMaterialGroupsRefdata$: Observable<Action> = this.actions$.pipe(
    ofType(refdataActions.RefdataActionTypes.GetMaterialGroups),
    mergeMap(() =>
    this.srvRefdata.getMaterialGroups<IMaterialGroupRefdataModel[]>().pipe(
        map((response: IMaterialGroupRefdataModel[]) => (new refdataActions.GetMaterialGroupsSuccess(response))),
        catchError(err => of(new refdataActions.GetMaterialGroupsError(err),
          new refdataActions.ShowGlobalFeedback(err)))
      )
    )
  );
  @Effect()
  getChannelsRefdata$: Observable<Action> = this.actions$.pipe(
    ofType(refdataActions.RefdataActionTypes.GetChannels),
    mergeMap(() =>
    this.srvRefdata.getChannels<IChannelRefdataModel[]>().pipe(
        map((response: IChannelRefdataModel[]) => (new refdataActions.GetChannelsSuccess(response))),
        catchError(err => of(new refdataActions.GetChannelsError(err),
          new refdataActions.ShowGlobalFeedback(err)))
      )
    )
  );

  constructor(private actions$: Actions,
              private srvRefdata: RefdataService) {
  }
}
