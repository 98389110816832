import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.template.html'
})
export class SidenavComponent implements OnInit {
  @Input('items') public menuItems: any[] = [];
  @Input('hasIconMenu') public hasIconTypeMenuItem: boolean;
  @Input('iconMenuTitle') public iconTypeMenuTitle: string;
  @Output() helpClicked = new EventEmitter<void>();

  constructor(private router: Router) {}
  ngOnInit() {}

  openHelp(){
    this.helpClicked.emit();
  }

  get currentRoute(): string{
    return this.router.url;
  }

  buildRouterLink(item, item2 = null, item3 = null): string{
    let link: string = "/";

    if (item && item.state){
      link += item.state;
    }

    if (item2){
      if (item2.fullState){
        link = "/" + item2.fullState;
      }else if (item2.state){
        link += "/" + item2.state;
      }
    }

    if (item3){
      if (item3.fullState){
        link = "/" + item3.fullState;
      }else if (item2.state){
        link += "/" + item3.state;
      }
    }

    return link;
  }
}